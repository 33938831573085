import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";

const QRDataTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 100;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://www.rcinfovision.in/api/get-all-details"
        );
        setData(response.data.response);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  const exportToExcel = () => {
    const filteredData = data.map((item, index) => ({
      srNo: index + 1,
      qrId: item.qrId,
      UID: item.UID,
      houseUidNo: item.houseUidNo,
      surveyCode: item.surveyCode,
      state: item.state,
      district: item.district,
      municipality: item.municipality,
      policeStation: item.policeStation,
      gpsLocation: item.gpsLocation,
      nameOfHouseOwner: item.nameOfHouseOwner,
      municipalWardSectorNo: item.municipalWardSectorNo,
      landDeed: item.landDeed,
      landDeedYear: item.landDeedYear,
      residentSince: item.residentSince,
      mobileNo: item.mobileNo,
      streetName: item.streetName,
      postOfficeName: item.postOfficeName,
      pincode: item.pincode,
      nearestLandMark: item.nearestLandMark,
      houseOwnerAadharNo: item.houseOwnerAadharNo,
      totalFamilyMembers: item.totalFamilyMembers,
      familyMemberAadharCard: item.familyMemberAadharCard,
      nonAadharFamilyMembers: item.nonAadharFamilyMembers,
      familyMemberVoterCard: item.familyMemberVoterCard,
      nonVoterFamilyMembers: item.nonVoterFamilyMembers,
      passport: item.passport,
      typeOfOwnership: item.typeOfOwnership,
      statusOfProperty: item.statusOfProperty,
      propertyTaxPay: item.propertyTaxPay,
      lastPaymentYear: item.lastPaymentYear,
      houseType: item.houseType,
      govtWaterFacility: item.govtWaterFacility,
      waterConnectionNumber: item.waterConnectionNumber,
      boreWell: item.boreWell,
      latrineToilet: item.latrineToilet,
      latrineStatus: item.latrineStatus,
      electricity: item.electricity,
      electricalConnectionNumber: item.electricalConnectionNumber,
      caste: item.caste,
      religion: item.religion,
      totalPlotArea: item.totalPlotArea,
      totalBuildCoveredArea: item.totalBuildCoveredArea,
      buildAreaFloorDetails: item.buildAreaFloorDetails,
      inPlotAreaCommercialUse: item.inPlotAreaCommercialUse,
      doorToDoorGarbageCollection: item.doorToDoorGarbageCollection,
      garbageCollectionBy: item.garbageCollectionBy,
      agriculturalLand: item.agriculturalLand,
      courtCase: item.courtCase,
      familyMemberCriticalDisease: item.familyMemberCriticalDisease,
      nameOfDisease: item.nameOfDisease,
      nameOfMember: item.nameOfMember,
      domesticAnimal: item.domesticAnimal,
      rentedPerson: item.rentedPerson,
      numberOfRentedFamilyMember: item.numberOfRentedFamilyMember,
      availAnyGovernmentFacility: item.availAnyGovernmentFacility,
      nameOfFacility: item.nameOfFacility,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "QR Data");
    XLSX.writeFile(workbook, "QR_Data_Report.xlsx");
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>QR Data Table</h2>
        <button onClick={exportToExcel} className="btn btn-primary">
          Export to Excel
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Sr No</th>
            <th>QR ID</th>
            <th>UID</th>
            <th>House UID</th>
            <th>Survey Code</th>
            <th>State</th>
            <th>District</th>
            <th>Municipality</th>
            <th>Police Station</th>
            <th>GPS Location</th>
            <th>House Owner</th>
            <th>Sector No</th>
            <th>Land Deed</th>
            <th>Land Deed Year</th>
            <th>Resident Since</th>
            <th>Mobile No</th>
            <th>Street Name</th>
            <th>Post Office</th>
            <th>Pincode</th>
            <th>Nearest Landmark</th>
            <th>Owner Aadhar No</th>
            <th>Total Family Members</th>
            <th>Family Member Aadhar</th>
            <th>Non Aadhar Members</th>
            <th>Family Member Voter</th>
            <th>Non Voter Members</th>
            <th>Passport</th>
            <th>Ownership</th>
            <th>Property Status</th>
            <th>Tax Pay</th>
            <th>Last Payment Year</th>
            <th>House Type</th>
            <th>Govt Water Facility</th>
            <th>Water Connection No</th>
            <th>Bore Well</th>
            <th>Latrine Toilet</th>
            <th>Latrine Status</th>
            <th>Electricity</th>
            <th>Electrical Connection No</th>
            <th>Caste</th>
            <th>Religion</th>
            <th>Total Plot Area</th>
            <th>Build Covered Area</th>
            <th>Build Area Floor Details</th>
            <th>Commercial Use</th>
            <th>Garbage Collection</th>
            <th>Garbage Collection By</th>
            <th>Agricultural Land</th>
            <th>Court Case</th>
            <th>Critical Disease</th>
            <th>Name of Disease</th>
            <th>Name of Member</th>
            <th>Domestic Animal</th>
            <th>Rented Person</th>
            <th>Rented Family Members</th>
            <th>Government Facility</th>
            <th>Facility Name</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((item, index) => (
            <tr key={item._id}>
              <td>{offset + index + 1}</td>
              <td>{item.qrId}</td>
              <td>{item.UID}</td>
              <td>{item.houseUidNo}</td>
              <td>{item.surveyCode}</td>
              <td>{item.state}</td>
              <td>{item.district}</td>
              <td>{item.municipality}</td>
              <td>{item.policeStation}</td>
              <td>{item.gpsLocation}</td>
              <td>{item.nameOfHouseOwner}</td>
              <td>{item.municipalWardSectorNo}</td>
              <td>{item.landDeed}</td>
              <td>{item.landDeedYear}</td>
              <td>{item.residentSince}</td>
              <td>{item.mobileNo}</td>
              <td>{item.streetName}</td>
              <td>{item.postOfficeName}</td>
              <td>{item.pincode}</td>
              <td>{item.nearestLandMark}</td>
              <td>{item.houseOwnerAadharNo}</td>
              <td>{item.totalFamilyMembers}</td>
              <td>{item.familyMemberAadharCard}</td>
              <td>{item.nonAadharFamilyMembers}</td>
              <td>{item.familyMemberVoterCard}</td>
              <td>{item.nonVoterFamilyMembers}</td>
              <td>{item.passport}</td>
              <td>{item.typeOfOwnership}</td>
              <td>{item.statusOfProperty}</td>
              <td>{item.propertyTaxPay}</td>
              <td>{item.lastPaymentYear}</td>
              <td>{item.houseType}</td>
              <td>{item.govtWaterFacility}</td>
              <td>{item.waterConnectionNumber}</td>
              <td>{item.boreWell}</td>
              <td>{item.latrineToilet}</td>
              <td>{item.latrineStatus}</td>
              <td>{item.electricity}</td>
              <td>{item.electricalConnectionNumber}</td>
              <td>{item.caste}</td>
              <td>{item.religion}</td>
              <td>{item.totalPlotArea}</td>
              <td>{item.totalBuildCoveredArea}</td>
              <td>{item.buildAreaFloorDetails}</td>
              <td>{item.inPlotAreaCommercialUse}</td>
              <td>{item.doorToDoorGarbageCollection}</td>
              <td>{item.garbageCollectionBy}</td>
              <td>{item.agriculturalLand}</td>
              <td>{item.courtCase}</td>
              <td>{item.familyMemberCriticalDisease}</td>
              <td>{item.nameOfDisease}</td>
              <td>{item.nameOfMember}</td>
              <td>{item.domesticAnimal}</td>
              <td>{item.rentedPerson}</td>
              <td>{item.numberOfRentedFamilyMember}</td>
              <td>{item.availAnyGovernmentFacility}</td>
              <td>{item.nameOfFacility}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default QRDataTable;
