import React, { useEffect, useState } from "react";
import axios from "axios";

const ShowQr = () => {
  const [qrCodes, setQRCodes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    // Fetch the data from the API
    axios
      .get("https://www.rcinfovision.in/api/get-all-qrs")
      .then((response) => {
        setQRCodes(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the QR code data!", error);
      });
  }, []);

  const handleChangePage = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(qrCodes.length / itemsPerPage);
  const currentItems = qrCodes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    let startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    let endPage = Math.min(
      totalPages,
      currentPage + Math.floor(maxPageNumbersToShow / 2)
    );

    if (currentPage <= Math.floor(maxPageNumbersToShow / 2)) {
      endPage = Math.min(totalPages, maxPageNumbersToShow);
    }

    if (currentPage + Math.floor(maxPageNumbersToShow / 2) >= totalPages) {
      startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handleChangePage(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <h2>QR Codes</h2>
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>QR ID</th>
            <th>QR Code URL</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((qrCode, index) => (
            <tr key={qrCode._id}>
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td>{qrCode.qrId}</td>
              <td>{qrCode.qrCodeUrl}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => handleChangePage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPageNumbers()}
        <button
          onClick={() => handleChangePage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ShowQr;
